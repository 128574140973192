#preloader{
  color: inherit;
  background-color: black;
  height: 100vh;
  width:100vw;
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}