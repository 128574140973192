:root {
  --mode-scale: 0;
  --blackhole-color: white;
  --blackhole-color-2: black;
  --blackhole-bg-1-d: #9448bc;
  --blackhole-bg-2-d: #90fcf9;
  --blackhole-bg-3-d: #9448bc; /* FF6F00 */
  --blackhole-bg-1-w: #059eff;
  --blackhole-bg-2-w: #44e1fe;
  --blackhole-bg-3-w: #00b2ff;
  --body-height: null;
  --tag-bg: black;
  --tag-color: white;
  --input-bg: null;
  --option-bg: null;
  --option-highlight-bg: null;
  --option-highlight-color:null;
  --chip-text-color: null;
  --text-color: null;
}

html {
  background: none;
}

* {
  scrollbar-width: 0.8rem;
  scrollbar-color: #888 white;
}
/* width */
::-webkit-scrollbar {
  width: 0.8rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
  border: 2px solid white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#bg-blur {
  backdrop-filter: blur(75px);
  height: var(--body-height);
  width: 100%;
  position: absolute;
  margin: auto;
  min-height: 100vh;
  z-index: -2;
}

#desktop-nav-bar {
  width: 100%;
  height: 8vh;
  display: grid;
  grid: auto/ repeat(8, 1fr);
  justify-content: center;
  align-items: center;
  position: fixed;
  color: inherit;
  overflow: hidden;
  clip: rect(auto, auto, auto, auto);
  z-index: 15;
}
#mobile-nav-bar {
  width: 100%;
  height: 64px;
  display: grid;
  grid: auto/ repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  position: fixed;
  color: inherit;
  overflow: hidden;
  z-index: 15;
}
#desktop-logo {
  margin-left: 1vw;
  text-decoration: none;
  grid-column: 1/5;
  color: inherit;
  height: 8vh;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
#desktop-logo h1{
  font-size: 3.6vh;
}
#desktop-logo .letter {
  opacity: 0;
  position: relative;
}
#mobile-logo {
  margin-left: 5vw;
  text-decoration: none;
  grid-column: 1/5;
  color: inherit;
  height: 64px;
  width: fit-content;
}
#mobile-logo h1 {
  height: 100%;
  width: fit-content;
}
#desktop-menu {
  grid-column: 5/8;
  display: flex;
  align-items: center;
  gap: 5%;
  color: inherit;
  text-align: center;
  flex-wrap: wrap;
}
#desktop-menu a {
  font-size: 2vh;
  text-decoration: none;
  color: inherit;
  width: max-content;
}
#desktop-dark-mode {
  width: fit-content;
  height: fit-content;
  border: none;
  background-color: transparent;
  position: relative;
  grid-column: 8;
  margin: auto;
}
#mode-contents {
  width: fit-content;
  height: fit-content;
}
#dark-icon,
#light-icon {
  font-size: 36px;
}

#hamburger-button {
  background: transparent;
  border: none;
  grid-column: 8;
  z-index: 20;
  display: flex;
  margin-right: 1em;
}

div.ripple {
  position: fixed;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-background 2000ms ease-in;
  background-color: white;
  z-index: -10;
}

@keyframes ripple-background {
  to {
    transform: scale(var(--mode-scale));
  }
}

@keyframes spin1 {
  100% {
    transform: rotate(360deg);
  }
}

#mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100%;
  text-align: center;
  color: inherit;
  background: white;
  opacity: 0;
  display: grid;
  grid: 1fr auto/auto;
  justify-content: center;
  z-index: 9;
  display: none;
  top: 0;
}
#mobile-menu a {
  text-decoration: none;
  list-style: none;
  color: inherit;
  width: 100%;
}
#mobile-menu-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  width: 90vw;
  font-size: 2.4rem;
}
#mobile-dark-div {
  display: flex;
  justify-content: center;
}
#mobile-menu #mobile-dark-mode {
  margin-bottom: 30%;
  top: 0;
}
#mobile-dark-mode {
  width: fit-content;
  height: fit-content;
  border: none;
  background-color: transparent;
  position: relative;
  top: 35%;
}

#home-body {
  width: 100%;
  position: relative;
  height: var(--body-height);
}
#home-body-intro {
  height: 100vh;
  width: 100%;
  position: absolute;
  /* z-index: -1; */
  color: inherit;
  background-color: transparent;
}

#home-title-1 {
  margin-top: 20vh;
  text-align: center;
}
#home-title-1 .letter {
  opacity: 0;
}

#blackhole-div {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
#blackhole-outline {
  position: absolute;
}
#blackhole-outline path {
  fill: none;
  stroke: var(--blackhole-color);
  stroke-width: 1px;
}
#blackhole-fill {
  position: absolute;
  transform: scale(0);
}
#blackhole-fill path {
  fill: var(--blackhole-color);
}
#blackhole-fill-2 {
  position: absolute;
  transform: scale(0);
}
#blackhole-fill-2 path {
  fill: var(--blackhole-color-2);
  stroke: var(--blackhole-color-2);
  stroke-width: 1.1px;
}
#blackhole-home {
  filter: drop-shadow(0px 0px 10px var(--blackhole-color));
  z-index: 9;
}
#blackhole-home path {
  fill: var(--blackhole-color-2);
  stroke: var(--blackhole-color-2);
}
.blackhole {
  width: 20vw;
  height: 20vh;
}
#blackhole-bg-1-dark {
  width: 40vw;
  height: 40vw;
  border-radius: 63% 50% 55% 42% / 67% 47% 65% 47%;
  z-index: -4;
  opacity: 0.3;
  position: absolute;
  background: linear-gradient(-45deg, var(--blackhole-bg-1-d), #121212, #121212);
}
#blackhole-bg-1-light {
  width: 40vw;
  height: 40vw;
  border-radius: 63% 50% 55% 42% / 67% 47% 65% 47%;
  z-index: -4;
  opacity: 0.3;
  position: absolute;
  background: linear-gradient(-45deg, white, white, white);
}
#blackhole-bg-2-dark {
  position: absolute;
  width: 5vw;
  height: 5vw;
  border-radius: 100%;
  background: var(--blackhole-bg-2-d);
  z-index: -4;
  border: 60px double var(--blackhole-bg-2-d);
  box-sizing: content-box;
  padding: 40px;
  opacity: 1;
}
#blackhole-bg-2-light {
  position: absolute;
  width: 15vw;
  height: 15vw;
  border-radius: 100%;
  background: var(--blackhole-bg-2-w);
  z-index: -4;
  border: 60px double var(--blackhole-bg-2-w);
  box-sizing: content-box;
  padding: 40px;
  opacity: 1;
}
#blackhole-bg-3-dark {
  position: absolute;
  width: 10vw;
  height: 10vw;
  background: var(--blackhole-bg-3-d);
  border-radius: 63% 50% 55% 42% / 67% 47% 65% 47%;
  z-index: -3;
  opacity: 1;
}
#blackhole-bg-3-light {
  position: absolute;
  width: 15vw;
  height: 15vw;
  background: var(--blackhole-bg-3-w);
  border-radius: 63% 50% 55% 42% / 67% 47% 65% 47%;
  z-index: -3;
  opacity: 1;
}
#blackhole-bg-blur {
  /* backdrop-filter: blur(75px); */
  height: 200%;
  width: 100%;
  position: absolute;
  margin: auto;
  z-index: -2;
}
#home-arrow {
  width: 5vw;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 90vh;
  mix-blend-mode: difference;
}

#home-arrow path {
  fill: none;
  stroke: white;
  stroke-width: 1px;
}

#home-body-projects {
  height: auto;
  width: 100%;
  top: 100vh;
  position: absolute;
}
#home-body-projects h1 {
  text-align: center;
  margin: 2em 0 2.6em 0;
  font-weight: 450;
}

.list-project-l {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 5%;
  margin-bottom: 6rem;
}
.list-project-r {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  gap: 5%;
  margin-bottom: 6rem;
}

.list-project-img {
  transition: margin 1000ms;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
}

.list-project-img img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.list-project-l .list-project-img {
  width: 30vw;
  height: 30vh;
  margin-left: 10vw;
}

.list-project-r .list-project-img {
  width: 30vw;
  height: 30vh;
  margin-right: 10vw;
}

.list-project-text {
  width: 50%;
  height: 100%;
  transition: flex-shrink 1000ms, max-height 1000ms;
}
.list-project-text h2,
.grid-project-text h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.4rem;
  font-weight: 350;
}
.list-project-text p,
.grid-project-text p {
  text-align: center;
  width: 75%;
  margin: auto;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 1em;
  margin-bottom: 1.4em;
}
.list-project-text p{
  font-size: 1.2rem;
}
.list-project-tags,
.grid-project-tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.6em;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.grid-project-l {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 5%;
  margin-bottom: 6rem;
}
.grid-project-r {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  gap: 5%;
  margin-bottom: 6rem;
}

.grid-project-r .grid-project-img {
  margin-right: 5vw;
}

.grid-project-l .grid-project-img {
  margin-left: 5vw;
}

.grid-project-img {
  position: relative;
  transition: margin 1000ms;
  width: 40vw;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
    border-radius: 6px;
}
.grid-project-img img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.grid-project-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(1, 1, 1, 0.9);
  transition: 0.5s;
  opacity: 0;
}
.grid-project-img:hover:before {
  opacity: 1;
}
.grid-project-img:hover > .grid-project-text {
  opacity: 1;
}

.grid-project-text {
  position: absolute;
  flex-shrink: 1000000;
  opacity: 0;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white !important;
  transition: flex-shrink 1000ms, opacity 1000ms, max-height 1000ms;
}

.grid-project-l .list-project-text,
.grid-project-r .list-project-text {
  flex-shrink: 1000;
  overflow: hidden;
  white-space: nowrap;
}

.tag {
  text-decoration: none;
  color: var(--tag-bg);
  background-color: transparent;
  border-radius: 1rem;
  border-style: solid;
  padding: 0.3em 0.8em 0.5em 0.8em;
  font-size: 0.8rem;
  font-weight: 600;
  border-width: 0.2em;
  border-color: var(--tag-bg);
}
button.tag {
  padding: 0.5em 0.8em 0.5em 0.8em;
}
.tag:hover {
  background-color: var(--tag-bg);
  border-color: var(--tag-bg);
  color: var(--tag-color);
}

.grid-project-tags .tag {
  border-color: #cac6ff !important;
  color: #cac6ff !important;
}
.grid-project-tags .tag:hover {
  background-color: #cac6ff !important;
  border-color: #cac6ff !important;
  color: #121212 !important;
}

.project-link {
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
  gap: inherit;
  margin-bottom: inherit;
}


#projects-body {
  width: 100%;
  position: relative;
}
#projects-body-tags-bar {
  position: relative;
  top: 8vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 1rem;
}
#projects-body-tags-bar-tags {
  position: relative;
  max-width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.6em;
}
#projects-body-bar {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  fill: var(--text-color);
  stroke: var(--text-color);
}
#projects-body-bar-options {
  position: relative;
  width: 75%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  fill: inherit;
  stroke: inherit;
}
#projects-body-filter-search-bar {
  opacity: 0;
}
.search-wrapper {
  max-width: 60vw !important;
  min-height: 2.5em !important;
  background: var(--input-bg);
  border: none !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4em 0;
}
.search-wrapper .custom-close {
  margin-left: 0.2rem;
}
.search-wrapper .chip {
  background: var(--option-highlight-bg);
  color: var(--chip-text-color);
  fill: var(--chip-text-color);
  margin-bottom: 0;
}
#chip-close-icon {
  height: 24px;
  width: auto;
}
#chip-close-icon path {
  fill: inherit;
}
.search-wrapper input {
  color: inherit !important;
  min-width: 140px;
}
.optionContainer,
.optionListContainer {
  border: none !important;
  background: transparent !important;
}
.option {
  background: var(--option-bg) !important;
}
.notFound {
  background: var(--option-bg) !important;
}
.optionListContainer li:hover {
  background: var(--option-highlight-bg) !important;
  color:var(--option-highlight-color)!important
}
.highlightOption {
  background: var(--option-highlight-bg) !important;
  white-space: normal !important;
}

#projects-body-bar-filter-button {
  background: transparent;
  border: none;
}
#projects-body-bar-filter-icon {
  fill: inherit;
  stroke: none !important;
}

#projects-body-bar-view-button {
  background: transparent;
  border: none;
}

#projects-body-bar-list-icon {
  fill: inherit;
  stroke: inherit !important;
}
#projects-body-bar-view-icon {
  fill: inherit;
  stroke: inherit !important;
}

#projects-body h2 {
  position: relative;
}

#projects-body-projects {
  position: relative;
  width: 100%;
}
#projects-body-projects-title {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 4rem;
}
#projects-body-projects-list {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  /* transition: transform 1000ms; */
}

#project-body {
  width: 100%;
  position: relative;
}

#project-body {
  margin-top: 8vh;
}
#project-body-intro #project-title {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 3.4rem;
  text-align: center;
}
#project-body-intro #project-main-img {
  width: 100%;
  height: 50vh;
  opacity: 1;
  object-fit: contain;
}
#project-body-intro #project-summary {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  font-weight: 450;
  text-align: center;
}

/* #project-summary p {
  margin-top: 0;
  max-width: 35vw;
} */

#project-details{
  max-width: 35vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap:4rem;
  row-gap:1rem;
}

.details-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.details-item>*{
  width:100%;
}

.details-item h5{
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0;
}
.details-item p{
  font-weight: 350;
}

#project-body-intro #project-tags {
  max-width: 35vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  flex-wrap: wrap;
}

#project-body-content h3 {
  margin-top: 4rem;
  margin-bottom: 0;
  font-weight: 450;
  font-size: 2.4rem;
  text-align: center;
}
#project-body-content p {
  font-size: 1.2rem;
  max-width: 75%;
  text-align: left;
  margin: auto;
  margin-top: 2em;
}
#project-body-content a, #project-body-content a:visited{
  color:#059eff
}
.project-content-images,
.project-content-videos {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.project-content-images img {
  max-width: 60vw;
  object-fit: cover;
}
.project-content-videos video {
  /* width: 40%; */
  max-width: 60vw;
}
.vertical-video{
  width:40%;
}
.vertical-image{
  width:40%
}

#about-body {
  width: 100%;
  position: relative;
  margin-top:12vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap:1rem;
}

#about-body-para{
  text-align: left;
  width: 60%;
}
#about-body h1{
  text-align: center;
  font-size: 2.6rem;
  font-weight: 350;
  max-width: 65vw;
}
#about-body-para a, #about-body-para a:visited{
  color:#059eff
}

#about-body img{
  max-width: 60vw;
  object-fit: cover;
  border-radius: 50%;
  height: 20vw;
  width: 20vw;
  min-width: 256px;
  min-height: 256px;
}

#footer {
  width: 100%;
  height: 10vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
#footer p {
  text-align: center;
  margin: 0 8% 0 8%;
  font-size: 1rem;
  font-weight: 350;
}

#error-page{
  margin-top: 20vh;
  font-size: 2rem;
  text-align: center;
  /* color: red; */
  /* z-index: 100; */
}
#error-page>*{
  margin-top: 0;
}

@media (max-width: 768px) {
  /* #blackhole-home {
  filter: drop-shadow(0px 0px 10px var(--blackhole-color)) !important;
} */
  #home-body {
    width: 100%;
    position: relative;
    height: var(--body-height);
  }
  #home-title-1 {
    max-width: 85%;
    margin: auto;
    margin-top: 18vh;
    text-align: center;
    font-size: 1.8rem;
  }
  .blackhole {
    width: 50vw !important;
    height: 50vw !important;
  }
  #blackhole-bg-2-dark {
    width: 25vw !important;
    height: 25vw !important;
  }
  #blackhole-bg-3-dark {
    width: 45vw !important;
    height: 45vw !important;
  }
  #blackhole-bg-2-light {
    width: 40vw !important;
    height: 40vw !important;
  }
  #blackhole-bg-3-light {
    width: 45vw !important;
    height: 45vw !important;
  }
  #blackhole-bg-blur {
    /* backdrop-filter: blur(50px); */
  }
  #home-arrow {
    width: 20vw;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 75vh;
    mix-blend-mode: difference;
  }
  #home-body-projects h1 {
    text-align: center;
    margin: 0.6em 0 2em 0;
    font-weight: 450;
  }
  #projects-body-projects-list {
    transition: flex 1000ms;
  }
  .list-project-m {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5%;
    margin-bottom: 5rem;
    transition: margin 1000ms;
  }
  .list-project-img {
    width: 80vw;
    height: 30vh;
    margin: 0;
    margin-bottom: 2rem;
    transition: margin 1000ms;
  }
  .list-project-text {
    width: 100%;
    margin: 0;
    transition: margin 1000ms;
  }
  .list-project-text h2,
  .grid-project-text h2 {
    font-size: 2rem;
  }
  .list-project-text p,
  .grid-project-text p {
    font-size: 1rem;
    width: 90%;
  }
  .list-project-tags {
    width: 95%;
  }
  .grid-project-m {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5%;
    margin-bottom: 3rem;
    transition: margin 1000ms;
  }
  .grid-project-m .grid-project-img,
  .grid-project-m .list-project-img {
    width: 90vw;
    height: 35vh;
    transition: margin 1000ms;
  }
  .grid-project-img img,.list-project-img img{
    object-fit: cover;
  }
  .grid-project-text {
    position: absolute;
    flex-shrink: 1000000;
    opacity: 0;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    transition: margin 1000ms;
    color: white !important;
    overflow-x: hidden;
  }

  .grid-project-tags {
    width: 95%;
  }

  .grid-project-tags .tag {
    border-color: #cac6ff !important;
    color: #cac6ff !important;
  }
  .grid-project-tags .tag:hover {
    background-color: #cac6ff !important;
    border-color: #cac6ff !important;
    color: #121212 !important;
  }
  .tag {
    padding: 0.3em 0.8em 0.3em 0.8em;
  }
  .project-link {
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    flex-direction: inherit;
    gap: inherit;
    margin-bottom: inherit;
  }

  #about-body {
  width: 100%;
  position: relative;
  margin-top:100px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap:1rem;
}
#about-body h1{
  text-align: center;
  font-size: 1.6rem;
  font-weight: 350;
  max-width: 95vw;
}
#about-body-para{
  text-align: left;
  width: 90%;
}
#about-body-para a, #about-body-para a:visited{
  color:#059eff
}

#about-body img{
  max-width: 90vw;
  object-fit: cover;
  border-radius: 50%;
  height: 60vw;
  width: 60vw;
  min-width: 128px;
  min-height: 128px;
}


  #projects-body {
    width: 100%;
    position: relative;
  }
  /* #projects-body h1 {
    margin-top: 10vh;
  } */
  .search-wrapper {
    flex-wrap: nowrap;
    overflow-x: scroll;
    max-width: 50vw !important;
  }
  .search-wrapper::-webkit-scrollbar {
    height: 0;
  }

  #project-body {
    margin-top: 10vh;
  }
  #project-body-intro #project-title {
    font-size: 2.4rem;
  }
  #project-body-intro #project-main-img {
    height: 30vh;
  }
  #project-body-intro #project-summary {
    margin-top: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  /* #project-summary p {
    margin-top: 0;
    max-width: 95vw;
  } */

  #project-details{
  max-width: 95vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.details-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.details-item>*{
  width:100%;
}

.details-item h5{
  font-size: 1.6rem;
  font-weight: 450;
  margin: 0;
}
.details-item p{
  font-weight: 300;
}

  #project-body-intro #project-tags {
    max-width: 95vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    flex-wrap: wrap;
  }

  #project-body-content h3 {
    margin-top: 4rem;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 2rem;
    text-align: center;
  }
  #project-body-content p {
    max-width: 90%;
    text-align: left;
    margin: auto;
    margin-top: 2em;
  }
  #project-body-content a, #project-body-content a:visited{
  color:#059eff
}
  .project-content-images,
  .project-content-videos {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .project-content-images img {
    max-width: 90vw;
    object-fit: cover;
  }
  .project-content-videos video {
    max-width: 90vw;
  }
}
